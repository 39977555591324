<template>
  <div class="country-list" :class="isLangFixed ? 'country-fixed' : ''" @click="toggleLang">
    <span class="display-hidden-accessible">{{ locale.name.toUpperCase() }}</span>
    <component :is="`icon-flag-${locale.code}`" class="lang-item__icon" />
    <transition name="fade">
      <div v-if="hasLangOpen && locale">
        <nuxt-link
          v-for="(item, key) in langs"
          :key="key"
          :to="switchLocalePath(item.code)"
          :title="item.code"
          class="lang-item"
        >
          <component :is="`icon-flag-${item.code}`" class="lang-item__icon space-mt-1" />
        </nuxt-link>
      </div>
    </transition>
    <nuxt-link :to="switchLocalePath(locale.code)" :title="locale.name" class="lang-item" />
  </div>
</template>

<script>
import IconFlagFr from '~/assets/svg/icon-flag-fr.svg';
import IconFlagEn from '~/assets/svg/icon-flag-en.svg';
import IconFlagDe from '~/assets/svg/icon-flag-de.svg';

export default {
  components: {
    IconFlagFr,
    IconFlagEn,
    IconFlagDe,
  },
  props: {
    locale: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasLangOpen: false,
      langs: this.$i18n.locales.filter(
        item => ![this.$i18n.locale].includes(item.code)
      ),
    };
  },
  computed: {
    isLangFixed() {
      // not fixing the country list on specific pages
      return !/economy___|comptability___/.test(this.$route.name);
    },
  },
  methods: {
    toggleLang() {
      this.hasLangOpen = !this.hasLangOpen;
      this.langs = this.$i18n.locales.filter(
        item => ![this.$i18n.locale].includes(item.code)
      );
    },
  },
};
</script>

<style lang="scss">
.lang-item {
  display: block;
}

.lang-item__icon {
  display: block;
  width: 100%;
  height: 100%;
  border: solid math.div(2, 16) * 1rem white;
  border-radius: 50%;
}

.country-list {
  cursor: pointer;
  width: 42px;
  margin-top: -5px;
  z-index: 1000;
}

.country-fixed {
  position: fixed;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
