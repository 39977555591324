import * as types from './mutation-types';

/**
 * Economy actions
 * @return {Object}
 */
export default {
  /**
   * Get data from the economy endpoint.
   *
   * @param  {Vuex}   store   The store instance
   * @param  {Object} payload The payload to add to the Axios request
   * @return {Mixed}          The request data
   */
  async get(store, payload) {
    let response;
    try {
      response = await this.$axios.get(store.state.endpoint, payload);
    } catch (err) {
      throw new Error(err);
    }
    return response.data;
  },

  /**
   * Validate the post code for the 5th step.
   *
   * @see https://studiometa.atlassian.net/wiki/spaces/WEEN/pages/1541964068/tape+5+Code+postaux
   * @param  {Vuex} store The store instance
   * @return {void}
   */
  async validatePostCode(store) {
    // Remove error message when testing
    store.commit(types.SET_STEP_ERROR, {
      index: store.getters.stepIndex,
      error: '',
    });

    const postCode = store.getters.currentStep.value;
    const previousStep = store.state.steps[store.getters.stepIndex - 1];

    let isValid = false;

    if (previousStep.value === 'FR') {
      isValid = await store.dispatch('isValidFrenchPostCode', postCode);
    }

    if (previousStep.value === 'GB') {
      isValid = await store.dispatch('isValidGbPostCode', postCode);
    }

    if (!isValid) {
      store.commit(types.SET_STEP_ERROR, {
        index: store.getters.stepIndex,
        error: this.$i18n.t('economy.postcode.error'),
      });
    } else {
      store.dispatch('handlerNextStep', store.getters.stepIndex);
    }
  },

  /**
   * Validate a french postcode.
   *
   * @param  {Vuex}    store   The store instance
   * @param  {String}  payload The postcode to validate
   * @return {Boolean}         Is it a valid postcode?
   */
  async isValidFrenchPostCode(store, codePostal) {
    const endpoint = 'https://geo.api.gouv.fr/communes';
    let response = [];

    try {
      response = await this.$axios.$get(endpoint, { params: { codePostal } });
    } catch (err) {
      return false;
    }

    return response.length > 0;
  },

  /**
   * Validate a GB postcode.
   *
   * @param  {Vuex}    store   The store instance
   * @param  {String}  payload The postcode to validate
   * @return {Boolean}         Is it a valid postcode?
   */
  async isValidGbPostCode(store, postCode) {
    const endpoint = `https://api.postcodes.io/postcodes/${postCode}`;
    let response;

    try {
      response = await this.$axios.$get(endpoint);
    } catch (err) {
      return false;
    }

    return !!response;
  },

  /**
   * @todo docblock
   */
  handlerNextStep(store, stepNumber) {
    const newStepNumber = stepNumber + 2;
    this.$router.push(
      this.app.localePath({
        name: 'economy-step',
        params: { step: newStepNumber },
      })
    );
  },

  /**
   * @todo docblock
   */
  async onSubmit(store) {
    const params = {};
    store.state.steps.forEach(el => {
      params[el.name] = el.value;
    });
    const results = await store.dispatch('get', { params });
    store.commit(types.UPDATE_RESULTS, results.result);
    this.$router.push(
      this.app.localePath({
        name: 'economy-results',
      })
    );
  },

  /**
   * @todo docblock
   */
  updateStepValue(store, { index, value }) {
    store.commit(types.UPDATE_STEP_VALUE, {
      index,
      value,
    });
  },

  /**
   * Loops over fields and reset them when we first access the configurator
   *
   * @param  {Object}   options.state  The current state
   * @param  {Function} options.commit The commit function
   */
  resetFormState({ state, commit }) {
    state.steps.forEach((step, index) => {
      commit(types.SET_STEP_DEFAULT_VALUE, index);
    });
  },
};
