<template>
  <div class="progress || position-relative">
    <svg
      class="progress__icon"
      :height="radius * 2"
      :width="radius * 2">
      <circle
        class="progress__meter"
        :stroke-dasharray="`${circumference} ${circumference}`"
        stroke-width="2"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius" />
      <circle
        class="progress__value"
        :stroke-dasharray="`${strokeDashOffset} ${circumference}`"
        :stroke-dash-offset="strokeDashOffset"
        stroke-width="3"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius" />
    </svg>
    <div class="progress__count || type-body type-weight-700">
      {{ current }}/{{ total }}
    </div>
  </div>
</template>

<script>
  /**
   * Component that displays the progress of the configurators steps
   * @displayName Progress
   */
  export default {
    props: {
      /**
       * The current step
       * @type {String}
       */
      current: {
        type: Number,
        required: true,
      },
      /**
       * The total number of steps
       * @type {String}
       */
      total: {
        type: Number,
        required: true,
      },
      /**
       * The radius of the circle
       * @type {Number}
       */
      radius: {
        type: Number,
        default: 28,
      },
    },
    data() {
      const normalizedRadius = this.radius - (3 * 2);
      const circumference = Math.PI * (normalizedRadius * 2);

      return {
        normalizedRadius,
        circumference,
      };
    },
    computed: {
      strokeDashOffset() {
        return this.circumference * this.current / this.total;
      },
    },
  };
</script>

<style lang="scss">
  .progress {
    width: 56px;
    height: 56px;
  }

  .progress__icon {
    transform: rotate(-90deg);
  }

  .progress__meter,
  .progress__value {
    fill: transparent;
  }

  .progress__meter {
    stroke: c('grey');
  }

  .progress__value {
    stroke: c('green');
    stroke-linecap: round;
    // The `all` property seems to be the only value that is working to animate
    // the `stroke-dash-offset` of the SVG circle.
    // stylelint-disable-next-line
    transition: all 1s $out-expo;
  }

  .progress__count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
