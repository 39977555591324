/**
 * Set the store global state of the Nuxt app.
 *
 * @param  {Vuex} options.store The store Vuex instance
 * @param  {Vuex} options.env   Environment variables
 * @return {void}
 */
export default ({ store, env }) => {
  store.commit('SET_ENV_APP_URL', env.APP_URL);
  store.commit('SET_ENV_APP_ENV', env.APP_ENV);
};
