/**
 * Update a step value mutation
 * @type {String}
 */
export const UPDATE_STEP_VALUE = 'UPDATE_STEP_VALUE';

/**
 * Update the results mutation
 * @type {String}
 */
export const UPDATE_RESULTS = 'UPDATE_RESULTS';

/**
 * Set a step value with the default value
 * @type {String}
 */
export const SET_STEP_DEFAULT_VALUE = 'SET_STEP_DEFAULT_VALUE';

/**
 * Set a step error message
 * @type {String}
 */
export const SET_STEP_ERROR = 'SET_STEP_ERROR';
