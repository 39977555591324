/**
 * Compatibility state
 * @return {Object}
 */
export default () => ({
  endpoint: 'https://plugin.ween.fr/boilers.php',
  result: '0',
  boilersOtherOption: {
    label: {
        fr: 'Je ne sais pas',
        en: 'I don\'t know',
    },
    value: '111',
  },
  steps: [
    /**
     * Step one
     */
    {
      title: {
        fr:
          '<span class="color-green">Quelle énergie utilise votre</span> système de chauffage ?',
        en:
          '<span class="color-green">Which energy powers</span> your heating system?',
      },
      type: 'FormRadio',
      name: 'e',
      get defaultValue() {
        return '';
      },
      value: '',
      required: true,
      onSubmit: 'onSubmitEnergy',
      props: {
        options: [
          {
            label: {
              fr: 'Gaz',
              en: 'Gas',
            },
            value: '1',
            icon: 'energy-compatibility-1',
          },
          {
            label: {
              fr: 'Électricité',
              en: 'Electricity',
            },
            value: '3',
            icon: 'energy-compatibility-3',
          },
          {
            label: {
              fr: 'Fioul',
              en: 'Fuel oil',
            },
            value: '2',
            icon: 'energy-compatibility-2',
          },
          {
            label: {
              fr: 'Bois',
              en: 'Wood',
            },
            value: '5',
            icon: 'energy-compatibility-5',
          },
          {
            label: {
              fr: 'Hybride',
              en: 'Hybrid',
            },
            value: '7',
            icon: 'energy-compatibility-7',
          },
          {
            label: {
              fr: 'PAC',
              en: 'PAC',
            },
            value: '4',
            icon: 'energy-compatibility-4',
          },
          {
            label: {
              fr: 'Pellet',
              en: 'Pellet',
            },
            value: '6',
            icon: 'energy-compatibility-6',
          },
        ],
      },
    },
    /**
     * Step two
     */
    {
      title: {
        fr:
          '<span class="color-green">Quelle est la marque de votre</span> équipement de chauffage ?',
        en:
          '<span class="color-green">What is the brand of your</span> heating system?',
      },
      type: 'FormSelect',
      classes: [
        'grid__col-6--s',
        'grid__push-3--s',
        'grid__col-4--m',
        'grid__push-4--m',
      ],
      name: 'b',
      get defaultValue() {
        return '';
      },
      value: '',
      required: true,
      onSubmit: 'onSubmitBrand',
      props: {
        options: [],
        attr: {
          name: 'b',
        },
        config: {
          clearable: false,
          placeholder: {
            en: 'Select a brand',
            fr: 'Par exemple : Gretel',
          },
        },
      },
    },
    /**
     * Step three
     */
    {
      title: {
        fr:
          '<span class="color-green">Quel est le modèle de votre</span> équipement de chauffage ?',
        en:
          '<span class="color-green">What is the model of your</span> heating system?',
      },
      type: 'FormSelect',
      classes: [
        'grid__col-6--s',
        'grid__push-3--s',
        'grid__col-4--m',
        'grid__push-4--m',
      ],
      name: 'm',
      get defaultValue() {
        return '';
      },
      value: '',
      required: true,
      onSubmit: 'onSubmitModel',
      props: {
        options: [],
        attr: {
          name: 'm',
        },
        config: {
          clearable: false,
          placeholder: {
            fr: 'Par exemple : Gialix',
            en: 'For example : Gialix',
          },
        },
      },
    },
    /**
     * Step four
     */
    {
      title: {
        fr:
          '<span class="color-green">Quel est le modèle de régulation de votre</span> équipement de chauffage ?',
        en:
          '<span class="color-green">What is the regulation model of your</span> heating equipment ?',
      },
      type: 'FormSelect',
      classes: [
        'grid__col-6--s',
        'grid__push-3--s',
        'grid__col-4--m',
        'grid__push-4--m',
      ],
      name: 'm',
      get defaultValue() {
        return '';
      },
      value: '',
      required: false,
      onSubmit: 'onSubmitRegulationModel',
      props: {
        options: [],
        attr: {
          name: 'm',
        },
        config: {
          clearable: false,
          placeholder: {
            fr: 'Sélectionnez une régulation',
            en: 'Select a regulation',
          },
        },
      },
    },
  ],
});
