<!-- eslint-disable max-len -->
<i18n lang="yaml">
  en:
    title: Oops,<br><span class="color-red">it's cold here.</span>
    description: Error {code}
    back: Back to home
  fr:
    title: Oups, <br><span class="color-red">Il fait froid par ici.</span>
    description: Erreur {code}
    back: Revenir à l'accueil
</i18n>
<!-- eslint-enable max-len -->

<template>
  <section class="page-center space-py-4 type-align-center type-align-left--s grid | confirmation">
    <div class="confirmation__circle-picture" />
    <div class="grid__row">
      <div class="grid__col-12--xxs grid__col-6--s space-pb-10--s space-pt-5--s">
        <icon
          name="icon-unchecked-white"
          class="color-red"
          circle="red"
          width="24px"
          height="24px"
        />
        <!-- eslint-disable vue/no-v-html -->
        <h2 class="type-title-primary type-weight-700 space-mb-3" v-html="$t('title')" />
        <p class="space-mb-2" v-html="$t('description', { code: error.statusCode })" />

        <p class="space-mb-6">{{ error.message }}</p>

        <!-- eslint-enable vue/no-v-html -->
        <div class="space-mb-4--s">
          <nuxt-link
            v-if="$nuxt.$route.path !== '/' && error.statusCode === 500"
            :to="localePath('index')"
            class="btn btn--secondary btn--width-full-xxs btn--width-auto-xs space-mb-2"
          >{{ $t('back') }}</nuxt-link>
          <button
            v-else
            class="btn btn--secondary btn--width-full-xxs btn--width-auto-xs space-mb-2"
            @click="reloadPage()"
          >{{ $t('back') }}</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '~/components/Icon';

export default {
  layout: 'configurator',
  components: {
    Icon,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  nuxtI18n: {
    seo: true,
  },

  methods: {
    reloadPage() {
      window.location.reload(true);
    },
  },
};
</script>

<style lang="scss">
.confirmation {
  .icon--circle {
    display: inline-block;
    margin: 0 0 s(2) 0;
  }
}

.confirmation__circle-picture {
  z-index: l(under-default);
  position: absolute;
  top: 0;
  left: 50%;
  width: 150%;
  height: 0;
  padding-top: 150%;
  background-color: c('grey');
  background-image: url('/img/circle-picture-error.jpg');
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translate(-50%, -100%);

  @media #{media('s')} {
    top: unset;
    right: -50%;
    bottom: 10%;
    left: unset;
    width: 100%;
    padding-top: 100%;
    transform: unset;
  }

  @media #{media('m')} {
    right: -40%;
    bottom: 10%;
    width: 60rem;
    height: 60rem;
    padding-top: 0;
  }
}
</style>
