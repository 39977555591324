import Vue from 'vue';

/**
 * Media queries helper based on the defined `$breakpoints` map from the
 * SCSS Toolkit.
 *
 * This plugin defines an `$mq` object accessible through the app. This object
 * is reactive and contains the following properties:
 *
 * |        Property        |    Type    |              Desc.              |
 * |------------------------|------------|---------------------------------|
 * | `breakpoint`           | `String`   | The current breakpoint          |
 * | `breakpoints`          | `Object`   | The available breakpoints       |
 * | `is`                   | `Object`   | The available breakpoints state |
 * | `isOneOf(breakpoints)` | `Function` | Test a list of breakpoints      |
 *
 * Example usage:
 *
 * ```js
 * mq.breakpoint // 's'
 * mq.breakpoints // { s: 600, m: 900, l: 1200 }
 * mq.is // { s: true, m: 900, l: false }
 * mq.isOneOf(['m', 'l']) // false
 * mq.isOneOf('m l') // false
 * mq.isOneOf('s m') // true
 * ```
 *
 * ```vue
 * <my-mobile-component v-if="$mq.is.s" />
 * <my-desktop-component v-if="$mq.isOneOf('m l')" />
 * ````
 *
 * @see ~/assets/scss/globals/_base.scss:28
 *
 * @param  {Object}   ctx    The app context (not used in this plugin)
 * @param  {Function} inject The Nuxt method to inject properties into the app
 * @return {void}
 */
export default function(ctx, inject) {
  const mq = new Vue({
    data: () => ({
      // The default breakpoint is `l`, which means all conditionally
      // rendered components will be rendered server side as if the `l`
      // breakpoint were active.
      breakpoint: 'l',
      // The default values are extracted from the SCSS Toolkit in order to
      // make them available for the server side rendering.
      breakpoints: {
        xxs: 0,
        xs: 480,
        s: 768,
        m: 1024,
        l: 1280,
        xl: 1440,
        xxl: 1920,
      },
    }),
    computed: {
      is() {
        return Object.keys(this.breakpoints).reduce((acc, breakpoint) => {
          acc[breakpoint] = breakpoint === this.breakpoint;
          return acc;
        }, {});
      },
    },
    methods: {
      /**
       * Update the list of breakpoints
       * @return {void}
       */
      updateBreakpoints() {
        this.breakpoints = this.getBreakpoints();
      },
      /**
       * Get the list of all breakpoints from the `body::after` pseudo-element.
       *
       * @return {Object} An object of the breakpoints defined in CSS
       */
      getBreakpoints() {
        let breakpoints = {};
        try {
          breakpoints = JSON.parse(
            JSON.parse(
              window
                .getComputedStyle(document.body, '::after')
                .getPropertyValue('content')
            )
          );
        } catch (err) {
          console.warn(err);
        }
        return breakpoints;
      },
      /**
       * Update the current breakpoint value.
       *
       * @return {void}
       */
      updateCurrentBreakpoint() {
        this.breakpoint = this.getCurrentBreakpoint();
      },
      /**
       * Read the current breakpoint from the `body::before` pseudo-element.
       *
       * @return {String} The name of the current breakpoint
       */
      getCurrentBreakpoint() {
        return window
          .getComputedStyle(document.body, '::before')
          .getPropertyValue('content')
          .replace(/"/g, '');
      },
      /**
       * Test if one of the given breakpoints is the current breakpoint.
       *
       * @param  {Array|String}   breakpoints An array of names or a string of
       *                                      space separated names
       * @return {Boolean}                    Wether one of the breakpoint is
       *                                      active or not
       */
      isOneOf(breakpoints = []) {
        // If breakpoints given as a string, we split it
        if (typeof breakpoints === 'string') {
          // eslint-disable-next-line no-param-reassign
          breakpoints = breakpoints.split(' ');
        }

        return breakpoints.includes(this.breakpoint);
      },
    },
  });

  // We use the `window` object only client-side
  if (process.client) {
    mq.updateBreakpoints();
    // mq.updateCurrentBreakpoint();

    window.addEventListener(
      'load',
      () => {
        mq.updateCurrentBreakpoint();
      },
      { once: true }
    );

    let timer;
    window.addEventListener('resize', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        mq.updateCurrentBreakpoint();
      }, 100);
    });
  }

  // And finally we inject the API
  inject('mq', {
    get breakpoint() {
      return mq.breakpoint;
    },
    get breakpoints() {
      return mq.breakpoints;
    },
    get is() {
      return mq.is;
    },
    isOneOf: mq.isOneOf,
  });
}
