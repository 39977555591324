/**
 * @todo docblock
 */
export const UPDATE_STEP_OPTIONS = 'UPDATE_STEP_OPTIONS';

/**
 * @todo docblock
 */
export const UPDATE_STEP_VALUE = 'UPDATE_STEP_VALUE';

/**
 * @todo docblock
 */
export const UPDATE_RESULTS = 'UPDATE_RESULTS';

/**
 * Set a step value with the default value
 * @type {String}
 */
export const SET_STEP_DEFAULT_VALUE = 'SET_STEP_DEFAULT_VALUE';
