<template>
  <i
    v-if="circle"
    class="icon icon--circle">
    <span
      class="icon__inner"
      :class="innerClasses" />
    <component
      :is="name"
      :class="[
        classes,
      ]" />
  </i>
  <component
    :is="name"
    v-else
    :class="[
      'icon',
      classes,
    ]"
    v-bind="attrs" />
</template>

<script>
  import icons from '~/utils/get-icons';

  export default {
    components: {
      ...icons,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: null,
      },
      height: {
        type: String,
        default: null,
      },
      classes: {
        type: String,
        default: '',
      },
      opacity: {
        type: Boolean,
        default: true,
      },
      circle: {
        type: String,
        default: '',
        validator: (value) => {
          const verif = [
            'background-white',
            'background-white-smoke',
            'background-grey',
            'background-blue',
            'background-blue-dark',
            'background-green',
            'background-orange',
            'background-red',
          ].includes(`background-${value}`) > -1;
          return verif;
        },
      },
    },
    computed: {
      innerClasses() {
        const classes = [];

        if (this.opacity) {
          classes.push('icon__inner--opacity');
        }

        if (this.circle) {
          classes.push(`background-${this.circle}`);
        }

        return classes;
      },
      attrs() {
        const attrs = {};

        if (this.width) {
          attrs.width = this.width;
        }

        if (this.height) {
          attrs.height = this.height;
        }

        return attrs;
      },
    },
  };
</script>

<style lang="scss">
  .icon {
    line-height: 0;
  }

  .icon--circle {
    display: block;
    width: space(6);
    height: space(6);
    margin: auto;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  .icon__inner {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: background-color 0.4s $out-expo;
  }

  .icon__inner--opacity {
    opacity: 0.3;
  }
</style>
