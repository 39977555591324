<template>
  <div class="lang-switcher">
    <div class="lang-switcher__current">
      <lang-item :locale="invertedLocale" />
    </div>
  </div>
</template>

<script>
import LangItem from './LangItem';

export default {
  components: {
    LangItem,
  },
  computed: {
    invertedLocale() {
      return this.$i18n.locales.find(
        locale => locale.code === this.$i18n.locale
      );
    },
  },
};
</script>

<style lang="scss">
.lang-switcher {
  position: relative;
  width: 2rem;
  height: 2rem;
}
</style>
