/**
 * Set the store global state of the Nuxt app.
 *
 * @param  {Vuex} options.store The store Vuex instance
 * @return {void}
 */
export default ({ store }) => {
  window.onNuxtReady(() => {
    store.commit('SET_NUXT_READY', true);
  });
};
