export default {
  /**
   * Set the nuxt ready state.
   *
   * @param {Object}  state The current store state
   * @param {Boolean} value The new value to set
   */
  SET_NUXT_READY(state, value) {
    state.isNuxtReady = value;
  },

  /**
   * Set the API URL value.
   *
   * @param {Object} state The current store state
   * @param {String} value The value to set
   */
  SET_ENV_APP_URL(state, value) {
    state.env.APP_URL = value;
  },

  /**
   * Set the API URL value.
   *
   * @param {Object} state The current store state
   * @param {String} value The value to set
   */
  SET_ENV_APP_ENV(state, value) {
    state.env.APP_ENV = value;
  },
};
