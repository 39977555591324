import sort from '~/utils/sort';
import * as types from './mutation-types';

/**
 * Compatibility actions
 * @return {Object}
 */
export default {
  /**
   * Get data from the compatibility endpoint.
   *
   * @param  {Vuex}   store   The store Vuex instance
   * @param  {Object} payload The request payload, passed directly to Axios
   * @return {Object}         The data from the API
   */
  async get(store, payload) {
    let response;
    try {
      response = await this.$axios.get(store.state.endpoint, payload);
    } catch (err) {
      throw new Error(err);
    }
    return response.data;
  },

  /**
   * Get all boiler brands for a given energy.
   *
   * @param  {Vuex}   store  The store Vuex instance
   * @param  {Number} energy The ID of the energy to filter with
   * @return {Array}         A list of boiler brands
   */
  async getBrands(store, energy) {
    const response = await store.dispatch('get', {
      params: {
        e: energy,
      },
    });

    const brands = response.result.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));

    return sort('label', brands);
  },

  /**
   * Get a list of all available brands.
   *
   * @param  {Vuex}  store The store Vuex instance
   * @return {Array}       A list of all boiler brands
   */
  async getAllBrands(store) {
    const brands = new Set();

    try {
      await Promise.all(
        store.state.energies.map(async energy => {
          const energyBrands = await store.dispatch('getBrands', energy.value);
          if (!Array.isArray(energyBrands)) {
            console.warn(
              `Failing while fetching brands for ${energy.label}.`,
              energyBrands
            );
          }

          energyBrands.forEach(brand => {
            brands.add(brand);
          });
        })
      );
    } catch (err) {
      throw new Error(err);
    }

    // Return an alphabetically sorted array
    return sort('label', Array.from(brands));
  },

  /**
   * Get all boiler models for a given energy and brand.
   *
   * @param  {Vuex}   store          The store Vuex instance
   * @param  {Number} options.energy The ID of the energy to filter with
   * @param  {Number} options.brand  The ID of the brand to filter with
   * @return {Array}                 A list of boiler brands
   */
  async getModels(store, { energy, brand }) {
    const response = await store.dispatch('get', {
      params: {
        e: energy,
        b: brand,
      },
    });

    const models = response.result.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));

    return sort('label', models);
  },

  /**
   * Get the boiler regulation models for a given brand.
   *
   * @param  {Vuex}   store         The store Vuex instance
   * @param  {Number} options.brand The ID of the brand to filter with
   * @return {Array}                A list of regulation models
   */
  async getRegulationModels(store, brand) {
    const response = await store.dispatch('get', {
      params: {
        e: 8,
        b: brand,
      },
    });

    const regulationModels = response.result.map(({ id, name }) => ({
      label: name,
      value: String(id),
    }));

    return sort('label', regulationModels);
  },

  /**
   * Get the results.
   *
   * @param  {Vuex}   store The store Vuex instance
   * @return {Number}       The result's value
   */
  async getResult(store, model) {
    const response = await store.dispatch('get', {
      params: {
        m: model,
      },
    });

    return response.result.shift();
  },

  /**
   * Go to the next step.
   *
   * @param  {Vuex}   store The store Vuex instance
   * @param  {Number} index The current step index (zero-based)
   * @return {void}
   */
  goToNextStep(store, index) {
    this.$router.push(
      this.app.localePath({
        name: 'compatibility-step',
        params: { step: index + 2 },
      })
    );
  },

  /**
   * Go to the result page while setting its value.
   *
   * @param  {Vuex} store The store Vuex instance
   * @return {void}
   */
  goToResult(store, result) {
    store.commit(types.UPDATE_RESULTS, result);
    this.$router.push(this.app.localePath('compatibility-results'));
  },

  /**
   * Dispatch actions to be executed before each step access.
   *
   * @param  {Vuex}   store The store Vuex instance
   * @param  {Number} index The current step index (zero-based)
   * @return {void}
   */
  beforeEachStep(store, index) {
    store.dispatch('checkPreviousStepValue', index);
    store.dispatch('resetNextStepsValues', index);
  },

  /**
   * Check if the previous step has a value, else redirect to it.
   *
   * @param  {Vuex}   store The store Vuex instance
   * @param  {Number} index The current step index (zero-based)
   * @return {void}
   */
  checkPreviousStepValue(store, index) {
    // Do nothing if we are on the first step
    if (index === 0) {
      return;
    }

    const previousIndex = index - 1;
    const previousStep = store.state.steps[previousIndex];

    if (
      previousStep.required &&
      previousStep.value === previousStep.defaultValue
    ) {
      this.$router.push(
        this.app.localePath({
          name: 'compatibility-step',
          params: { step: previousIndex + 1 },
        })
      );
    }
  },

  /**
   * Reset the value of each steps after the given one.
   *
   * @param  {Vuex}   store The store Vuex instance
   * @param  {Number} index The current step index (zero-based)
   * @return {void}
   */
  resetNextStepsValues(store, index) {
    store.state.steps.forEach((step, i) => {
      if (i > index) {
        store.commit(types.SET_STEP_DEFAULT_VALUE, i);
      }
    });
  },

  /**
   * Handler for the energy choice submission.
   *
   * @param  {Vuex} store The store Vuex instance
   * @return {void}
   */
  async onSubmitEnergy(store) {
    const { idEnergy, currentStepIndex } = store.getters;
    const brands = await store.dispatch('getBrands', idEnergy);
    store.commit(types.UPDATE_STEP_OPTIONS, {
      index: currentStepIndex + 1,
      options: brands,
    });
    store.dispatch('goToNextStep', currentStepIndex);
  },

  /**
   * Handler for the brand choice submission.
   *
   * @param  {Vuex} store The store Vuex instance
   * @return {void}
   */
  async onSubmitBrand(store) {
    const { idBrand, idEnergy, currentStepIndex } = store.getters;
    const models = await store.dispatch('getModels', {
      energy: idEnergy,
      brand: idBrand,
    });

    store.commit(types.UPDATE_STEP_OPTIONS, {
      index: currentStepIndex + 1,
      options: models,
    });
    store.dispatch('goToNextStep', currentStepIndex);
  },

  /**
   * Handler for the brand choice submission.
   *
   * @param  {Vuex} store The store Vuex instance
   * @return {void}
   */
  async onSubmitModel(store) {
    const { idBrand, idModel, currentStepIndex } = store.getters;

    const result = await store.dispatch('getResult', idModel);
    if (result === '3') {
        const regulationModels = await store.dispatch(
          'getRegulationModels',
          idBrand
        );

        store.commit(types.UPDATE_STEP_OPTIONS, {
        index: currentStepIndex + 1,
        options: regulationModels,
      });

      store.dispatch('goToNextStep', currentStepIndex);
      return;
    }

    store.dispatch('goToResult', result);
  },

  /**
   * Handler for the brand choice submission.
   *
   * @param  {Vuex} store The store Vuex instance
   * @return {void}
   */
  async onSubmitRegulationModel(store) {
    const { idRegulationModel } = store.getters;
    const result = await store.dispatch('getResult', idRegulationModel);
    store.dispatch('goToResult', result);
  },

  /**
   * Update the given step value.
   *
   * @param  {Vuex}   store         The store Vuex instance
   * @param  {Number} options.index The index of the step to update (zero-based)
   * @param  {String} options.value The value to set
   * @return {void}
   */
  updateStepValue(store, { index, value }) {
    store.commit(types.UPDATE_STEP_VALUE, {
      index,
      value,
    });
  },
};
