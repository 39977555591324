/**
 * Set the store global state of the Nuxt app.
 *
 * @param  {Vuex} options.store The store Vuex instance
 * @return {void}
 */
export default async ({ store, isDev, env }) => {
  if (isDev && env.VUEX_DEBUG === 'true') {
    const { default: chalk } = await import('chalk');
    const { default: stringify } = await import('json-stringify-safe');
    const log = console.log.bind(console, chalk.green('[Vuex]'));

    store.subscribe(({ type, payload }) => {
      // Do not log the route module
      if (type.startsWith('route')) {
        return;
      }

      log(
        chalk.magenta('mutation'),
        chalk.white(type),
        process.server ? stringify(payload) : payload
      );
    });

    store.subscribeAction(({ type, payload }) => {
      // Do not log the route module
      if (type.startsWith('route')) {
        return;
      }

      let formattedPayload = process.server ? stringify(payload) : payload;

      if (type === 'nuxtServerInit') {
        formattedPayload = '';
      }

      log(chalk.blue('action  '), chalk.white(type), formattedPayload);
    });
  }
};
