import * as types from './mutation-types';

/**
 * Compatibility mutations
 * @return {Object}
 */
export default {
  /**
   * @todo docblock
   */
  [types.UPDATE_STEP_OPTIONS](state, { index, options }) {
    state.steps[index].props.options = options;
  },
  /**
   * @todo docblock
   */
  [types.UPDATE_STEP_VALUE](state, { index, value }) {
    state.steps[index].value = value;
  },
  /**
   * @todo docblock
   */
  [types.UPDATE_RESULTS](state, data) {
    state.result = Number(data);
  },

  /**
   * Set the value of a step to be the defaultValue
   *
   * @param {Object} state The current state
   * @param {Number} index The step index
   */
  [types.SET_STEP_DEFAULT_VALUE](state, index) {
    state.steps[index].value = state.steps[index].defaultValue;
  },
};
