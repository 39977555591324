/**
 * Get a sorted by key copy of an array.
 *
 * @param  {String} key The key to use to sort the array
 * @param  {Array}  arr The array to sort
 * @return {Array}      A copy of the array to sort
 */
export default (key, arr) => {
  return [...arr].sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};
