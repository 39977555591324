/**
 * i18n configuration, used to add a `currentLocale` getter.
 *
 * @param  {Object} options.app The app object
 * @return {void}
 */
export default function({ app }) {
  Object.defineProperty(app.i18n, 'currentLocale', {
    get() {
      return app.i18n.locales.find(({ code }) => code === app.i18n.locale);
    },
  });
}
