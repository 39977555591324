const CLASS_NAME = 'has-no-scroll';

/**
 * Add a class on the documentElement to block the scroll
 */
export function disableScroll() {
  document.documentElement.classList.add(CLASS_NAME);
}

/**
 * Removes the class on the documentElement to enable the scroll
 */
export function enableScroll() {
  document.documentElement.classList.remove(CLASS_NAME);
}

export default {
  disableScroll,
  enableScroll,
};
