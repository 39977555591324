/**
 * Economy state
 * @return {Object}
 */
export default () => ({
  endpoint: 'https://plugin.ween.fr/eco.php',
  result: null,
  steps: [
    {
      title: {
        fr: '<span class="color-green">Quel est</span> le type de logement ?',
        en: '<span class="color-green">What type of</span> housing is it?',
      },
      classes: 'grid__col-4--m grid__push-4--m',
      type: 'FormRadio',
      name: 't',
      get defaultValue() {
        return '';
      },
      value: '',
      onSubmit: 'handlerNextStep',
      props: {
        options: [
          {
            label: {
              fr: 'Appartement',
              en: 'Flat',
            },
            value: '1',
            icon: 'property-1',
          },
          {
            label: {
              fr: 'Maison',
              en: 'House',
            },
            value: '2',
            icon: 'property-2',
          },
        ],
      },
    },
    {
      title: {
        fr:
          '<span class="color-green">Quelle énergie utilise votre</span> système de chauffage ?',
        en:
          '<span class="color-green">Which energy powers your</span> heating system?',
      },
      classes: 'grid__col-10--m grid__push-1--m',
      type: 'FormRadio',
      name: 'e',
      get defaultValue() {
        return '';
      },
      value: '',
      onSubmit: 'handlerNextStep',
      props: {
        options: [
          {
            label: {
              fr: 'Gaz',
              en: 'Gas',
            },
            value: '1',
            icon: 'energy-economy-1',
          },
          {
            label: {
              fr: 'Électricité',
              en: 'Electricity',
            },
            value: '2',
            icon: 'energy-economy-2',
          },
          {
            label: {
              fr: 'Fioul',
              en: 'Oil',
            },
            value: '3',
            icon: 'energy-economy-3',
          },
          {
            label: {
              fr: 'Bois',
              en: 'Wood',
            },
            value: '4',
            icon: 'energy-economy-4',
          },
          {
            label: {
              fr: 'PAC',
              en: 'Heat pump',
            },
            value: '5',
            icon: 'energy-economy-5',
          },
          {
            label: {
              fr: 'Pellet',
              en: 'Wood pellets',
            },
            value: '6',
            icon: 'energy-economy-6',
          },
        ],
      },
    },
    {
      title: {
        fr:
          '<span class="color-green">Quelle est la surface</span> de votre logement ?',
        en: '<span class="color-green">What is your</span> home surface area?',
      },
      classes: 'grid__col-10--m grid__push-1--m',
      type: 'FormRadio',
      name: 'a',
      get defaultValue() {
        return '';
      },
      value: '',
      onSubmit: 'handlerNextStep',
      props: {
        options: [
          {
            label: '50m²',
            value: '50',
          },
          {
            label: '75m²',
            value: '75',
          },
          {
            label: '100m²',
            value: '100',
          },
          {
            label: '150m²',
            value: '150',
          },
          {
            label: '200m²',
            value: '200',
          },
          {
            label: '300m²',
            value: '300',
          },
        ],
      },
    },
    {
      title: {
        fr: '<span class="color-green">Dans quel pays</span> résidez-vous ?',
        en: '<span class="color-green">Which country</span> do you live in?',
      },
      classes: 'grid__col-4--m grid__push-4--m',
      type: 'FormRadio',
      name: 'c',
      get defaultValue() {
        return '';
      },
      value: '',
      onSubmit: 'handlerNextStep',
      props: {
        options: [
          {
            label: {
              fr: 'France',
              en: 'France',
            },
            value: 'FR',
            icon: 'country-FR',
          },
          {
            label: {
              fr: 'Royaume-Uni',
              en: 'UK',
            },
            value: 'GB',
            icon: 'country-GB',
          },
        ],
      },
    },
    {
      title: {
        fr:
          '<span class="color-green">Quel est le code postal</span> de votre logement ?',
        en: '<span class="color-green">What is your</span> postcode?',
      },
      classes: 'grid__col-6--s grid__push-3--s grid__col-4--m grid__push-4--m',
      type: 'FormText',
      name: 'z',
      get defaultValue() {
        return '';
      },
      value: '',
      onSubmit: 'validatePostCode',
      props: {
        attr: {
          placeholder: 'addresses.postcode',
        },
      },
    },
    {
      title: {
        fr:
          "<span class='color-green'>Quelle est</span> la période de construction du bâtiment ?",
        en: "<span class='color-green'>Year of</span> building construction?",
      },
      type: 'FormRadio',
      name: 'g',
      get defaultValue() {
        return '';
      },
      value: '',
      onSubmit: 'handlerNextStep',
      props: {
        options: [
          {
            label: {
              fr: 'Avant 1955',
              en: 'Before 1955',
            },
            value: '2.0',
          },
          {
            label: '1955 – 1974',
            value: '1.8',
          },
          {
            label: '1974 – 1982',
            value: '1.4',
          },
          {
            label: '1983 – 1989',
            value: '1.2',
          },
          {
            label: '1990 – 2000',
            value: '1.0',
          },
          {
            label: '2001 – 2005',
            value: '0.8',
          },
          {
            label: {
              fr: 'Après 2005',
              en: 'After 2005',
            },
            value: '0.73',
          },
        ],
      },
    },
    {
      title: {
        fr:
          '<span class="color-green">Quelle température</span> de confort désirez-vous ?',
        en:
          '<span class="color-green">What comfort temperature</span> do you prefer?',
      },
      classes: 'grid__col-6--m grid__push-3--m',
      type: 'FormRange',
      name: 's',
      get defaultValue() {
        return '21';
      },
      value: '21',
      onSubmit: 'handlerNextStep',
      props: {
        options: {
          step: '1',
          min: '18',
          max: '25',
        },
      },
    },
    {
      title: {
        fr: '<span class="color-green">Vous</span> êtes chez vous ?',
        en: '<span class="color-green">Are</span> you more like?',
      },
      classes: 'grid__col-6--m grid__push-3--m',
      type: 'FormRadio',
      name: 'p',
      get defaultValue() {
        return '';
      },
      value: '',
      onSubmit: 'onSubmit',
      props: {
        options: [
          {
            label: {
              fr: 'Rarement',
              en: 'Rarely',
            },
            value: '3',
            icon: 'frequence-rarely',
          },
          {
            label: {
              fr: 'Le soir',
              en: 'The night',
            },
            value: '2',
            icon: 'frequence-night',
          },
          {
            label: {
              fr: 'Souvent',
              en: 'Frequently',
            },
            value: '1',
            icon: 'frequence-often',
          },
        ],
      },
    },
  ],
});
