/* eslint-disable */
/* ================================
 * Get all icons
 * ================================ */

const files = require.context('~/assets/svg', true, /[\s\S]*$/);
const dictionary = {};

files.keys().forEach(path => {
  const file = path.replace('./', '');
  const key = file.replace('.svg', '');
  const content = async () => await import(`~/assets/svg/${file}`);

  try {
    dictionary[key] = atob(content.split(',').pop());
  } catch (e) {
    dictionary[key] = content;
  }
});

export default dictionary;
