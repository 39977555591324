/**
 * Social links for Ween
 * @type {Object}
 */
export const socialLinks = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/WeenHome',
  },
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/ween-sas',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/Ween_Home',
  },
  {
    name: 'Youtube',
    url: 'https://www.youtube.com/channel/UCAnm89eIj1etMP80Q7n5sqA',
  },
];

/**
 * Download links of the app for both platforms
 * @type {Object}
 */
export const appDownloadLinks = [
  {
    name: 'app.appStore',
    url: 'app.appleUrl',
    logo: 'app.appleLogo',
    label: 'app.appleLabel',
  },
  {
    name: 'app.googleStore',
    url: 'app.googleUrl',
    logo: 'app.googleLogo',
    label: 'app.googleLabel',
  },
];

/**
 * List of the press articles about Ween
 * @type {Object}
 */
export const partners = [
  {
    name: 'bfm',
    link: 'https://youtu.be/TKPEHbuyw5M',
  },
  {
    name: 'lefigaro',
    link:
      'https://www.lefigaro.fr/secteur/high-tech/start-up/2018/01/08/32004-20180108ARTFIG00008-ween-passe-du-thermostat-a-l-intelligence-artificielle.php',
  },
  {
    name: 'lesechos',
    link:
      'https://business.lesechos.fr/entrepreneurs/actu/0211667888131-comment-la-pepite-ween-mise-sur-les-partenariats-pour-grandir-304269.php',
  },
  {
    name: 'europe1',
    link: 'https://www.youtube.com/watch?v=XOhfxEl349I',
  },
  {
    name: 'lexpress',
    link:
      'https://lexpansion.lexpress.fr/entreprises/six-idees-malignes-et-ecolo-developpees-par-des-pme_1740008.html',
  },
  {
    name: 'wired',
    link: 'https://www.wired.com/?p=1954372',
  },
  {
    name: 'ces',
    link: 'https://www.ces.tech/',
  },
];

export default {
  socialLinks,
  appDownloadLinks,
  partners,
};
