export default {
  /**
   * Get the index of the step
   * @param  {Object} state         The current state
   * @param  {Object} getters       The getters
   * @param  {Object} options.route The current route object
   * @return {Number}               The index of the step
   */
  stepIndex(state, getters, { route }) {
    const { step } = route.params;

    return step ? parseInt(step, 10) - 1 : null;
  },

  /**
   * Get the current step
   * @param  {Object} state   The current state
   * @param  {Object} getters The getters
   * @return {Object}         The current step object
   */
  currentStep(state, { stepIndex }) {
    return state.steps[stepIndex];
  },

  currentStepHasValue(state, { currentStep }) {
    if (!currentStep) {
      return false;
    }

    const { value } = currentStep;

    return value !== '' && value !== null;
  },
};
