export default {
  /**
   * Returns the total number of steps of the configurator
   * @param  {Object} state The current state
   * @return {Number}       The total number of steps
   */
  totalStep(state) {
    return state.steps.length;
  },

  /**
   * Get the zero-based current step index.
   *
   * @param  {Object} state     The module's current state
   * @param  {Object} getter    The module's current getters
   * @param  {Object} rootState The root current state
   * @return {Number}           The zero-based index of the current step
   */
  currentStepIndex(state, getter, rootState) {
    const { params } = rootState.route;
    return params.step ? Number(params.step) - 1 : -1;
  },

  /**
   * Get the current step configuration.
   *
   * @param  {Object} state                    The module's current state
   * @param  {Number} options.currentStepIndex The module's current step index
   * @return {Object}                          The current step configuration
   */
  currentStep(state, { currentStepIndex }) {
    return state.steps[currentStepIndex];
  },

  /**
   * The ID of the selected energy.
   *
   * @param  {Object} state The current state
   * @return {String}       The ID of the selected energy
   */
  idEnergy(state) {
    return state.steps[0].value;
  },

  /**
   * The ID of the selected brand.
   *
   * @param  {Object} state The current state
   * @return {String}       The ID of the selected brand
   */
  idBrand(state) {
    return state.steps[1].value;
  },

  /**
   * The ID of the selected model.
   *
   * @param  {Object} state The current state
   * @return {String}       The ID of the selected model
   */
  idModel(state) {
    return state.steps[2].value;
  },

  /**
   * The ID of the selected regulation model.
   *
   * @param  {Object} state The current state
   * @return {String}       The ID of the selected regulation model
   */
  idRegulationModel(state) {
    return state.steps[3].value;
  },
};
