var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.handleScroll),expression:"handleScroll"}],staticClass:"layout"},[_c('header',{class:[_vm.isFixed ? 'header-fixed' : '', 'layer-menu', 'layout__header']},[(_vm.$mq.isOneOf('xxs xs'))?_c('main-menu-mobile',{attrs:{"items":_vm.headerMenuMobileItems}}):_c('main-menu-desktop',{attrs:{"items":_vm.headerMenuDesktopItems}})],1),_vm._v(" "),_c('main',{staticClass:"layout__main"},[_c('nuxt')],1),_vm._v(" "),_c('footer',{staticClass:"layout__footer || color-white || background-blue"},[_c('div',{staticClass:"page-center || grid"},[_c('div',{staticClass:"grid__row grid__row--center space-pt-7--xxs space-pt-10--s space-py-10--l"},[_c('div',{staticClass:"grid__col-12--xxs grid__col-2--m icon type-align-center type-align-left--m space-mb-6--xxs space-mb-0--m"},[_c('nuxt-link',{staticClass:"display-inline-block",attrs:{"to":_vm.localePath('index')}},[_c('icon',{attrs:{"name":"logo-ween","width":"128px","height":"21px"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"grid__col-12--xxs grid__col-10--m"},[_c('ul',{staticClass:"flex flex-column--xxs flex-row--m align-center justify-end"},_vm._l((_vm.footerMenuPrimary),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(typeof item.if !== 'undefined' ? item.if : true),expression:"typeof item.if !== 'undefined' ? item.if : true"}],key:`footer-primary-item-${index}`,class:{
                'space-mt-3--xxs': index > 0,
                'space-mt-0--m': index > 0,
                'space-ml-5--m': index > 0,
                'space-ml-7--l': index > 0,
              }},[(item.component)?_c(item.component,_vm._b({tag:"component"},'component',item.props || {},false)):_c('nuxt-link',{staticClass:"footer__menu-primary-link || type-btn type-weight-700",class:item.classes,attrs:{"to":item.to,"hash":item.hash}},[_vm._v(_vm._s(item.label))])],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"grid__row grid__row--center space-pt-5--xxs space-pb-10--xxs"},[_c('div',{staticClass:"grid__col-12--xxs grid__col-3--m space-mb-8--xxs space-mb-0--m"},[_c('div',{staticClass:"flex justify-center--xxs justify-start--m"},_vm._l((_vm.socialLinks),function(social,index){return _c('a',{key:`footer-social-item-${index}`,class:[
                'social-link',
                'color-white',
                index > 0 ? 'space-ml-3--xxs space-ml-5--m' : '',
              ],attrs:{"href":social.url,"title":_vm.$t('social', { name: social.name }),"target":"_blank","rel":"nofollow,noopener,noreferrer"}},[_c('span',{staticClass:"display-hidden-accessible"},[_vm._v("\n                "+_vm._s(_vm.$t('social', { name: social.name }))+"\n              ")]),_vm._v(" "),_c('icon',{attrs:{"name":`icon-${social.name}`.toLowerCase(),"width":"32","height":"32","circle":"white"}})],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"grid__col-12--xxs grid__col-9--m"},[_c('ul',{staticClass:"flex flex-column--xxs flex-row--m align-center justify-end"},_vm._l((_vm.footerMenuSecondary),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(typeof item.if !== 'undefined' ? item.if : true),expression:"typeof item.if !== 'undefined' ? item.if : true"}],key:`footer-primary-item-${index}`,class:{
                'space-mt-3--xxs': index > 0,
                'space-mt-0--m': index > 0,
                'space-ml-5--m': index > 0,
                'space-ml-9--l': index > 0,
              }},[(item.component)?_c(item.component,_vm._g(_vm._b({tag:"component"},'component',item.props || {},false),item.on || {}),[_vm._v(_vm._s(item.label))]):_c('nuxt-link',{staticClass:"footer__menu-secondary-link",class:item.classes,attrs:{"to":item.to,"hash":item.hash}},[_vm._v(_vm._s(item.label))])],1)}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }