<i18n lang="yaml">
  en:
    back: Back to home
    buy: Buy now
  fr:
    back: Revenir à l'accueil
    buy: Acheter maintenant
</i18n>

<template>
  <div class="layout">
    <header class="layout-configurator-header">
      <div class="page-center || grid">
        <div class="layout-configurator-header__wrapper grid__row flex align-center">
          <div class="grid__col-12--xxs grid__col-4--xs">
            <vue-progress
              v-if="$mq.isOneOf('xxs xs') && currentStep !== null && totalStep"
              :current="currentStep"
              :total="totalStep"
              class="space-mx-auto space-my-4"
            />
            <nuxt-link
              v-if="!$mq.isOneOf('xxs xs')"
              :to="localePath('index')"
              :title="$t('back')"
              class="link"
            >
              <icon name="icon-arrow-left" class="space-mr-2" />
              {{ $t('back') }}
            </nuxt-link>
          </div>
          <div class="grid__col-12--xxs grid__col-4--xs icon type-align-center">
            <nuxt-link :to="localePath('index')">
              <icon
                name="logo-ween"
                width="100px"
                height="16px"
                classes="layout-configurator-header__logo"
              />
            </nuxt-link>
          </div>
          <div class="grid__col-12--xxs grid__col-4--xs icon type-align-right">
            <nuxt-link
              v-if="$mq.isOneOf('xxs xs')"
              :to="localePath('index')"
              :title="$t('back')"
              class="btn--no-appearance"
            >
              <icon name="icon-close" />
            </nuxt-link>
            <lang-switcher v-else class="display-inline-block" />
          </div>
        </div>
      </div>
    </header>

    <main class="layout__main">
      <div v-if="$mq.isOneOf('s m l xl xxl') && currentStep" class="page-center grid">
        <div class="grid__row grid__row--center">
          <div
            class="grid__col-10--xxs grid__push-1--xxs grid__col-8--s grid__push-2--s type-align-center"
          >
            <vue-progress
              :current="currentStep"
              :total="totalStep"
              class="space-mx-auto space-my-4"
            />
          </div>
        </div>
      </div>
      <nuxt />
    </main>
  </div>
</template>

<script>
import Icon from '~/components/Icon';
import LangSwitcher from '~/components/LangSwitcher';
import VueProgress from '~/components/Configurator/Progress';

export default {
  components: {
    Icon,
    LangSwitcher,
    VueProgress,
  },
  nuxtI18n: {
    seo: true,
  },

  computed: {
    headerMenuItems() {
      return [
        {
          to: {
            path: this.localePath('index'),
            hash: '#offers',
          },
          label: this.$t('buy'),
          classes: 'btn btn--primary',
        },
        {
          label: 'lang-switcher',
          component: LangSwitcher,
        },
      ];
    },
    currentStep() {
      return this.$route.params.step
        ? parseInt(this.$route.params.step, 10)
        : null;
    },
    configuratorName() {
      const { name } = this.$route;
      // Separator to split the route name is different for the intro page
      // so we split them both!
      return name
        .split('__')
        .shift()
        .split('-')
        .shift();
    },
    totalStep() {
      return this.$store.state[this.configuratorName].steps.length;
    },
  },
};
</script>

<style lang="scss">
.layout-configurator-header {
  padding: 0.445em 0;

  .progress {
    margin: 0;
  }

  @media #{md('s')} {
    padding: space(2) 0;
  }

  @media #{md('l')} {
    padding: space(4) 0;
  }
}

.layout-configurator-header__wrapper {
  min-height: 56px;
}

.layout-configurator-header__logo {
  @media #{md('s')} {
    width: 128px;
    height: 21px;
  }
}

.layout__main {
  @media #{md('s')} {
    min-height: calc(100vh - 120px);
  }

  @media #{md('l')} {
    min-height: calc(100vh - 168px);
  }
}
</style>
