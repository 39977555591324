<template>
  <nav :class="[
      'main-menu-mobile',
      isOpen ? 'is-open' : '',
    ]">
    <div class="page-center || grid">
      <div class="grid__row grid__row--center">
        <div class="grid__col-3--xxs grid__col-3--s">
          <div class="main-menu-mobile__container flex align-center type-align-left">
            <button
              type="button"
              class="main-menu-mobile__menu-btn btn btn--no-appearance layer-default"
              @click="handleClickMenu"
            >
              <icon name="icon-menu-burger" width="24px" height="24px" classes="icon-menu-burger" />
              <icon name="icon-menu-close" width="24px" height="24px" classes="icon-menu-close" />
            </button>
            <span class="space-ml-1">Menu</span>
          </div>
        </div>
        <div class="grid__col-6--xxs grid__col-6--s justify-center flex icon icon-logo">
          <nuxt-link :to="localePath('index')" class="layer-default || display-inline-block">
            <icon name="logo-ween" width="100px" height="16px" classes="header__logo" />
          </nuxt-link>
        </div>
        <div class="grid__col-3--xxs grid__col-3--s">
          <div class="main-menu-mobile__container flex align-center type-align-left">
            <lang-switcher class="main-menu-mobile__lang-switcher layer-default space-ml-auto" />
            <button
              type="button"
              class="main-menu-mobile__menu-btn btn btn--no-appearance layer-default space-ml-4"
              @click="goToProduct"
            >
              <icon name="icon-menu-cart" width="32px" height="32px" classes="icon-menu-burger" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-menu-mobile__offcanvas">
      <div class="page-center">
        <ul class="main-menu__list type-align-center">
          <li
            v-for="(item, index) in items"
            :key="`main-menu-desktop-item-${index}`"
            class="main-menu__item space-my-4"
          >
            <component :is="item.component" v-if="item.component" v-bind="item.props || {}" />
            <nuxt-link
              v-else
              :to="item.to"
              :hash="item.hash"
              :class="item.classes"
              class="main-menu__link || type-weight-700"
              @click="isOpen = false"
            >{{ item.label }}</nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Icon from '~/components/Icon';
import LangSwitcher from '~/components/LangSwitcher';
import { enableScroll, disableScroll } from '~/utils/has-scroll';

export default {
  components: {
    Icon,
    LangSwitcher,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    $route() {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
  },
  methods: {
    handleClickMenu() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        disableScroll();
      } else {
        enableScroll();
      }
    },
    goToProduct() {
      this.$router.push({
        path: this.localePath('index'),
        hash: '#offers',
      });
    },
  },
};
</script>

<style lang="scss">
.main-menu-mobile__lang-switcher {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.main-menu-mobile__menu-btn {
  .icon-menu-close,
  .icon-menu-burger {
    margin: 0 !important;
    transition: opacity 0.2s;
  }

  .icon-menu-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0;
  }
}

.main-menu-mobile {
  padding-top: 0.778em;
  padding-bottom: 0.778em;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  transition: color 0.2s;

  &.is-open {
    color: c('white');

    .main-menu-mobile__lang-switcher {
      pointer-events: auto;
      opacity: 1;
    }

    .icon-menu-burger {
      opacity: 0;
    }

    .icon-menu-close {
      opacity: 1;
    }
  }

  @media #{md('s')} {
    display: none;
  }
}

.main-menu-mobile__container {
  line-height: 0;
}

.main-menu-mobile__offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  color: c('white');
  background: c('blue');
  transform: translateX(-100%);
  transition: transform 0.2s;

  .is-open & {
    transform: translateX(0);
  }
}

.icon-logo {
  display: flex !important;
}
</style>
