import Vue from 'vue';
import * as types from './mutation-types';

/**
 * Economy mutations
 * @return {Object}
 */
export default {
  /**
   * Update the current step value with user choice
   *
   * @param {Object} state The current state
   * @param {Number} index The current step index
   * @param {Mixed}  value The value of the user input
   */
  [types.UPDATE_STEP_VALUE](state, { index, value }) {
    state.steps[index].value = value;
  },

  /**
   * Update the result wiht the data from the API
   *
   * @param {Object} state The current state
   * @param {Object} data  The data from the API
   */
  [types.UPDATE_RESULTS](state, data) {
    state.result = data;
  },

  /**
   * Set the value of a step to be the defaultValue
   *
   * @param {Object} state The current state
   * @param {Number} index The step index
   */
  [types.SET_STEP_DEFAULT_VALUE](state, index) {
    state.steps[index].value = state.steps[index].defaultValue;
  },

  /**
   * Set the given step error message.
   *
   * @param {Object} state         The current state
   * @param {Number} options.index The index of the step the error belongs to
   * @param {String} options.error The translated error message
   */
  [types.SET_STEP_ERROR](state, { index, error }) {
    Vue.set(state.steps[index], 'error', error);
  },
};
